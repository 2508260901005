module.exports = [{
      plugin: require('/tmp/62ff87ea/node_modules/gatsby-mdx/gatsby-browser'),
      options: {"plugins":[],"extensions":[".mdx",".md",".markdown"],"globalScope":"\n          import Aside from \"components/mdx/Aside\"\n\n          export default { Aside }\n        ","gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"backgroundColor":"#fafafa","maxWidth":1035,"sizeByPixelDensity":true}}]},
    },{
      plugin: require('/tmp/62ff87ea/node_modules/gatsby-plugin-catch-links/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('/tmp/62ff87ea/node_modules/gatsby-plugin-google-analytics/gatsby-browser'),
      options: {"plugins":[],"trackingId":"UA-58406625-2"},
    },{
      plugin: require('/tmp/62ff87ea/node_modules/gatsby-plugin-typography/gatsby-browser'),
      options: {"plugins":[],"pathToConfigModule":"src/lib/typography"},
    },{
      plugin: require('/tmp/62ff87ea/node_modules/gatsby-plugin-offline/gatsby-browser'),
      options: {"plugins":[]},
    }]
